<template>
  <div class="profile">
    <div class="minw" v-loading="mainloading">
      <!-- 上侧学校信息和图片 -->
      <div class="screen">
        <div class="left">
          <img :src="collegeEntity.collegeLogo" width="132px" height="132px" style="border-radius: 50%;"
            referrerpolicy="no-referrer" />
          <div class="centertitle">
            <span style="font-size: 18px; font-weight: bold; color: #333">{{ collegeEntity.collegeName }}</span>
            <!-- <span style="display: flex; align-items: center"><i class="el-icon-location-outline" style="font-size: 18px; color: #12B098">&nbsp;</i>{{ collegeEntity.collegeProvince }}{{
                  collegeEntity.collegeLocality
                }}</span> -->
            <span class="btnbox">
              <span>{{ collegeEntity.collegeType }} /</span>
              <span>{{ collegeEntity.collegeNature }} /</span>
              <span v-for="(item, index) in collegeEntity.labelList" :key="index">{{ item }}/ </span>

              <span>{{ collegeEntity.cityLevel }} /</span>
              <span>{{ collegeEntity.collegeArea }}</span>
            </span>
            <span style="font-size: 14px;margin-bottom: 13px;color: #09594D;">官方网址：<a
                :href="collegeEntity.collegeWeb">{{
      collegeEntity.collegeWeb }}</a></span>
            <span style="font-size: 14px;color: #09594D;">官方电话：{{ collegeEntity.collegeTelephone }}</span>
            <!-- <span>电子邮箱：&nbsp;&nbsp;{{ collegeEntity.collegeEmail }}</span> -->
          </div>
        </div>
        <img class="screenImg" :src="introductionList[0]" alt="">
      </div>

      <div class="schools">
        <div class="school_item" :class="{ activeSchoolItem: activeId == item.id }" v-for="(item, index) in schools"
          @click="handClick(item)">
          <img :src="activeId == item.id ? item.imgpath : item.imgpath_" alt="">
          <span>{{ item.text }}</span>
        </div>
      </div>
      <!-- 院校信息 -->
      <div v-show="activeId == 0" class="detailWrap">
        <div class="introduction">
          <div class="left" style="width: 485px;">
            <h1 class="schoolintro">院校概括</h1>
            <span class="pdetail">{{ collegeEntity.collegeDescriptions }}...
              <span style="color: #12B098;cursor: pointer;" @click="all()">全部</span>
            </span>
            <h2 class="schoolintro">高校排行({{ yearWithRanking ? yearWithRanking : '暂无排名' }})</h2>
            <p class="ranking" v-for="(item, index) in eranking" :key="index">
              <span>{{ !item.num ? '--' : item.num }}名</span>
              <span>{{ item.title }}</span>
            </p>
          </div>
          <el-carousel height="400px" indicator-position="none" style="flex:6" :autoplay="false" arrow="always">
            <el-carousel-item v-for="(item, index) in introductionList" :key="index">
              <img height="100%" width="100%" :src="item" alt="" style="border-radius:10px"></img>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div class="schools" style="padding-left: 15px;box-sizing: border-box;">
          <div class="  other">
            <img class="jtimg" :src="detailIconList[0].imgpath" alt="">
            <span>{{ collegeEntity.collegeNature }} </span>
          </div>
          <div class="  other">
            <img class="jtimg" :src="detailIconList[1].imgpath" alt="">
            <span>教育部</span>
          </div>
          <div class="  other">
            <img class="jtimg" :src="detailIconList[2].imgpath" alt="">
            <span>{{ collegeEntity.collegeType }} </span>
          </div>
          <div class="  other">
            <img class="jtimg" :src="detailIconList[3].imgpath" alt="">
            <span> 综合</span>
          </div>
          <div class="  other">
            <img class="jtimg" :src="detailIconList[4].imgpath" alt="">
            <span>{{ collegeEntity.degreePrograms || '--' }} </span>
          </div>
          <div class="  other">
            <img class="jtimg" :src="detailIconList[5].imgpath" alt="">
            <span>{{ collegeEntity.doctoralPrograms || '--' }} </span>
          </div>
          <div class="  other" style="width: 25%;">
            <img class="jtimg" :src="detailIconList[6].imgpath" alt="">
            <span>{{ collegeEntity.collegeProvince }} {{ collegeEntity.collegeLocality }}</span>
          </div>


        </div>
        <div class="schools" style="padding-left: 15px;box-sizing: border-box;">
           <div class="other"    @click="getYszy(2)">
            <img class="jtimg" :src="detailIconList[7].imgpath" alt="">
            <span>名企招聘<i class="el-icon-arrow-down"></i> </span>

          </div>
           <div class="  other"  @click="getYszy(1)">
            <img class="jtimg" :src="detailIconList[8].imgpath" alt="">
            <span> 优势专业 <i class="el-icon-arrow-down"></i>  </span>
          </div>

        </div>
      </div>
      <!-- 招生计划 -->
      <div v-show="activeId == 2" class="detailWrap" style="padding:0 20px">
        <div class="zz">
          <div style="padding: 50px;" v-if="tstr">
            <div class="text">
              <div class="textno">！</div>
            </div>
            <div class="textVip">
              免费 使用<span class="textspan">0</span>次 , <span class="textspan">开通VIP无限使用</span>
            </div>
            <div class="btn" @click="toVip">
              开通VIP
            </div>
          </div>
          <div v-else="tstr">
            <el-row :gutter="20">
              <!-- <el-col :span="3" class="
              piciFather">
                <span class="piciCss" :class="{ active: piciname == '普通批' }" @click="changepi('普通批')">普通批</span>
                <span class="piciCss" :class="{ active: piciname == '提前批' }" @click="changepi('提前批')">提前批</span>
              </el-col> -->
              <el-col :span="5">
                <el-select @change="getDataGrade" clearable style="width:100%" v-model="zsjhParams.particularYear"
                  placeholder="请选择年份">
                  <el-option v-for="index in 3" :key="index" :label="new Date().getFullYear() - index + 1"
                    :value="new Date().getFullYear() - index + 1">
                  </el-option>
                </el-select>
              </el-col>
              <el-col :span="5">
                <el-select @change="getDataGrade" clearable style="width:100%" v-model="zsjhParams.subjects"
                  placeholder="请选择科目">
                  <el-option label="物理" value="物理"></el-option>
                  <el-option label="历史" value="历史"></el-option>
                </el-select>
              </el-col>
              <el-col :span="5">
                <el-select @change="getDataGrade" clearable style="width:100%" v-model="zsjhParams.batchFile"
                  placeholder="请选择批次">
                  <el-option :label="item.label" :value="item.value" :key="index"
                    v-for="(item, index) in piciList"></el-option>
                </el-select>
              </el-col>
              <el-col :span="5">
                <el-select @change="getDataGrade" clearable style="width:100%"
                  v-model="zsjhParams.recruitStudentsCategory" placeholder="请选择招生类型">
                  <el-option v-for="(item, index) in zslxListCopy" :label="item.name" :key="index"
                    :value="item.name"></el-option>
                </el-select>
              </el-col>
            </el-row>
            <el-table :data="zsjhList" class="threeTable" :header-cell-style="{
      backgroundColor: '#f4f4f4',
      color: 'black',
      fontSize: '17px',
      textAlign: 'center',
    }" :cell-style="{
      textAlign: 'center',

    }" v-loading="loading">
              <el-table-column prop="majorCode" label="专业代号" width="80"></el-table-column>
              <el-table-column prop="majorname" label="专业名称" :show-overflow-tooltip="true"  width="180"></el-table-column>
              <el-table-column prop="professionalInstruction" label="专业简介"  ></el-table-column>
              <el-table-column prop="batchFile" label="批次"  width="120"></el-table-column>
              <el-table-column prop="subjects" label="主选科目"  width="80"></el-table-column>

              <el-table-column prop="recruitStudentsCategory" label="招生类型" width="110"></el-table-column>
              <el-table-column prop="checkRequest" label="选科要求"  width="80"></el-table-column>
              <el-table-column prop="recruitStudentNumber" label="招生人数"  width="80"></el-table-column>
              <el-table-column prop="professionalSystem" label="学制"  width="80"></el-table-column>
              <el-table-column prop="tuition" label="学费"  width="80"></el-table-column>
            </el-table>
          </div>
        </div>
      </div>
      <!-- 录取分数 -->
      <div v-show="activeId == 3" class="detailWrap" style="padding:0 20px">
        <el-row :gutter="20">
          <el-col :span="5">
            <el-select @change="luqufenshu" clearable style="width:100%" v-model="lqfsParams.particularYear"
              placeholder="请选择年份">
              <el-option v-for="index in 3" :key="index" :label="new Date().getFullYear() - index +1"
                :value="new Date().getFullYear() - index + 1">
              </el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select @change="luqufenshu" clearable style="width:100%" v-model="lqfsParams.subjects"
              placeholder="请选择科目">
              <el-option label="物理" value="物理"></el-option>
              <el-option label="历史" value="历史"></el-option>
            </el-select>
          </el-col>
          <el-col :span="5">
            <el-select @change="luqufenshu" clearable style="width:100%" v-model="lqfsParams.batchFile"
              placeholder="请选择批次">
              <el-option label="本科批" value="本科批"></el-option>
              <el-option label="专科批" value="专科批"></el-option>
            </el-select>
          </el-col>
        </el-row>
        <!--  height="70vh"  -->
        <el-table :data="lqfsList" class="threeTable":header-cell-style="{
      backgroundColor: '#f4f4f4',
      color: 'black',
      fontSize: '17px',
      textAlign: 'center',
    }" :cell-style="{
      textAlign: 'center',

      fontSize: '15px'
    }" v-loading="loading">
          <el-table-column prop="scorePlansEntity.majorCode" label="专业代号"  width="50"></el-table-column>
          <el-table-column prop="scorePlansEntity.majorname" label="专业名称" width="230">
              <template slot-scope="scope">
                 <div>{{ scope.row.scorePlansEntity.majorname }}</div>
                 <div class="f13">招生类型：{{ scope.row.scorePlansEntity.recruitStudentsCategory }}</div>
                 <div  class="f13">{{ scope.row.scorePlansEntity.professionalInstruction }}</div>
              </template>
          </el-table-column>
          <el-table-column prop="scorePlansEntity.batchFile" label="录取批次"></el-table-column>
          <el-table-column prop="scorePlansEntity.subjects" label="主选科目"  width="50"></el-table-column>

          <el-table-column prop="scorePlansEntity.score" label="最低分数"></el-table-column>
          <el-table-column prop="scorePlansEntity.ranking" label="最低位次"></el-table-column>
          <el-table-column prop="scorePlansEntity.cnmathScore" label="语数成绩"></el-table-column>
          <el-table-column prop="scorePlansEntity.cnmathHightScore" label="语数最高成绩">
          </el-table-column>
          <el-table-column prop="scorePlansEntity.englishScore" label="外语成绩"></el-table-column>
          <el-table-column prop="scorePlansEntity.preferScore" label="首选科目成绩"></el-table-column>
          <el-table-column prop="scorePlansEntity.hightestScore" label="再选科目最高成绩"></el-table-column>
          <el-table-column prop="scorePlansEntity.secondScore" label="再选科目次高成绩"></el-table-column>
        </el-table>
      </div>
      <!-- 招生简章 -->
      <div v-show="activeId == 4" class="Wrap">
        <div v-if="!zsjzDetailFlag">
          <div class="item" v-for="(item, index) in arrList" :key="index"
            :style="{ color: index === 0 ? '#12B098' : '' }" @click="zsjzDetailFlag = true; zsjzItem = item">
            <span>{{ item.fileName }}</span>
            <span>{{ item.createTime }}</span>
          </div>
        </div>
        <div v-else>
          <el-button style="display: block; margin-left: auto" @click="zsjzDetailFlag = false">返回</el-button>
          <h1 style="margin:0 0 15px 0;text-align:center;font-size:25px;font-weight:900">{{ zsjzItem.fileName }}
          </h1>
          <div class="ql-container ql-snow">
            <div class="ql-editor" v-html="zsjzItem.fileUrl">
            </div>
          </div>
        </div>
      </div>

    </div>
    <!-- 院校简介弹框 -->
    <el-dialog :title="collegeEntity.collegeName + '简介'" :visible.sync="dialogVisible" width="50%">
      <div style="height:500px;overflow-y:auto">
        <div class="ql-container ql-snow">
          <div class="ql-editor" v-html="collegeEntity.collegeDescription">
          </div>
        </div>
      </div>
    </el-dialog>
    <!-- 优势专业弹框 -->
     <el-dialog :title=" mqOrYs == 1 ? '优势专业' : '名企招聘'" :visible.sync="yszydialogVisible" width="50%">
      <!-- 优势专业 -->
       <div v-if="mqOrYs == 1" style="font-size:17px;line-height:30px">
            {{advantageMajorName || '暂无优势专业'}}
       </div>
       <!-- 名企招聘 -->
       <div  v-if="mqOrYs == 2" style="font-size:17px;line-height:30px">
           {{ collegeEntity.famousRecruit  || '暂无名企招聘'}}
       </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  collegequeryById,
  REQcollegemajor,
  REQcollegescore,
  REQenrollmentguide,
  REQqueryBatchScore,
  REQqueryPlanList,
  getYszyDetail
} from '@/api/checkschools.js'
import { queryByProfessionalCode } from '@/api/professionalinvestigation.js'
import { zslxListCopy } from "@/utils/fillingList.js";

import { cityList } from '@/utils/cityinfo.js'
import Quill from 'quill'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import '@/assets/css/font.css'
export default {
  name: 'profile',
  components: {},
  props: {},
  data() {
    return {
      tstr: true,
      schools: [
        {
          imgpath: require('@/assets/imgs/searchschool.png'),
          imgpath_: require('@/assets/imgs/searchschool_.png'),
          text: '院校概括',
          id: 0
        },
        {
          imgpath: require('@/assets/imgs/schoolstudentplan.png'),
          imgpath_: require('@/assets/imgs/schoolstudentplan_.png'),
          text: '招生计划',
          id: 2
        },
        {
          imgpath: require('@/assets/imgs/lqfs.png'),
          imgpath_: require('@/assets/imgs/lqfs_.png'),
          text: '录取分数',
          id: 3
        },
        {
          imgpath: require('@/assets/imgs/zszc.png'),
          imgpath_: require('@/assets/imgs/zszc_.png'),
          text: '招生简章',
          id: 4
        },
      ],
      zslxListCopy,
      mainloading: false,
      detailIconList: [
        { imgpath: require('@/assets/imgs/gongban.png') },
        { imgpath: require('@/assets/imgs/jiaoyubu.png') },
        { imgpath: require('@/assets/imgs/batchtype.png') },
        { imgpath: require('@/assets/imgs/zonghe.png') },
        { imgpath: require('@/assets/imgs/shuo.png') },
        { imgpath: require('@/assets/imgs/bo.png') },
        { imgpath: require('@/assets/imgs/location.png') },
         { imgpath: require('@/assets/imgs/pin.png') },
        { imgpath: require('@/assets/imgs/yszy.png') },



      ],

      activeId: 0,
      currentYear: undefined,
      cityList: cityList, // 省份数组
      wardenry: [], // 城市数组
      formInline: {
        particularYear: '',
        provide: '河北省',
        subjects: '物理'
      },
      dialogVisible: false,
      collegeDescription: '',
      collegeEntity: {},
      yearWithRanking: '',
      eranking: [],
      // 招生计划分页
      queryParamsGrade: {
        total: 28,
        pageNum: 1,
        pageSize: 10
      },

      // 学校简介图片
      introductionList: [],
      // 优势行业
      AdvantageMajorList: [],
      // 普通专业
      commonList: [],
      // 普通专业信息
      detailinfo: {},
      commonFlag: false, // 优势专业显示还是普通专业显示
      // 行业详情的显隐
      majorDetailFlag: false,
      loading: false,
      // 招生简章分页
      queryParams: {
        total: 50,
        pageNum: 1,
        pageSize: 10
      },
      // 招生简章的数组
      arrList: [],
      // 招生简章详情显隐
      zsjzDetailFlag: false,
      collegeNumber: undefined,

      majorDescription: '', // 专业历史
      majorName: '', // 专业名称
      // 招生简章内容
      zsjzItem: {},
      // 招生计划查询项
      zsjhParams: {
        batchFile: '',
        particularYear: new Date().getFullYear(),
        subjects: '',
        recruitStudentsCategory: ''
      },
      // 招生计划list
      zsjhList: [],
      piciList: [
        { name: '本科批', value: '本科批' },
        { name: '专科批', value: '专科批' },
         { name: '本科提前批A段', value: '本科提前批A段' },
          { name: '本科提前批B段', value: '本科提前批B段' },
          { name: '本科提前批C段', value: '本科提前批C段' },
          { name: '专科提前批', value: '专科提前批' },
      ],
      piciname: '',
      // 录取分数的查询项
      lqfsParams: {
        particularYear: new Date().getFullYear(),
        subjects: '',
        batchFile: '',
      },
      // 录取分数llist
      lqfsList: [],
      // 优势专业+名企招聘的弹框
      yszydialogVisible:false,
      // 优势专业名称
     advantageMajorName:'',
     mqOrYs:1 //1优势专业 2名企招聘
    }
  },
  computed: {},
  watch: {},
  async created() {
    this.mainloading = true

    if (this.$route.query.activeId) {
      this.activeId = this.$route.query.activeId

    }
    let particularYear = ''
    this.currentYear = new Date().getFullYear()
    const serverTime = new Date(this.$store.state.serverTime)
    const currentTime = serverTime.getTime()
    const ZLtime = await this.getAlltime('6') // 6是查询招录时间【10.30】
    if (currentTime < Date.parse(ZLtime)) {
      particularYear = serverTime.getFullYear() - 1
    } else {
      particularYear = serverTime.getFullYear()
    }
    this.formInline.particularYear = particularYear
    this.collegeNumber = this.$route.query.collegeNumber
    this.onload()
    // 如果activatedId == 2,调取招生计划
    if (this.$route.query.activeId == 2) {
      this.getDataGrade()
    }
  },
  mounted() {
    (this.$store.state.userInfo.userType == '01' || !localStorage.getItem('phonenumber')) ? this.tstr = true : this.tstr = false
    window.scrollTo({
      top: 0,
      behavior: 'smooth' // 平滑滚动
    });
  },
  methods: {
    toVip() {
      if (!localStorage.getItem('phonenumber')) {
        const channel = new BroadcastChannel('share')
        channel.postMessage(true)
        // this.$message.warning('请先登录')
        return
      }
      this.$router.push({ path: '/vip' })
    },
    // 院校详情
    onload() {
      this.loading = true

      var that = this
      var obj = {
        collegeNumber: this.collegeNumber
      }
      collegequeryById(obj).then((res) => {
        if (res.code == 200) {

          that.collegeNumber = res.data.collegeEntity.collegeNumber
          res.data.collegeEntity.collegeDescriptions = res.data.collegeEntity.collegeDescription.replace(/<.*?>/g, '').substring(0, 260)
          that.collegeEntity = res.data.collegeEntity
          if (res.data.collegeEntity.collegeLabel) {
            that.collegeEntity.labelList = res.data.collegeEntity.collegeLabel.split(',')
          }

          // 轮播
          res.data.collegeEntity.collegeImage1 =
            process.env.VUE_APP_BASE_API +
            res.data.collegeEntity.collegeImage1
          res.data.collegeEntity.collegeImage2 =
            process.env.VUE_APP_BASE_API +
            res.data.collegeEntity.collegeImage2
          res.data.collegeEntity.collegeImage3 =
            process.env.VUE_APP_BASE_API +
            res.data.collegeEntity.collegeImage3
          that.introductionList.push(res.data.collegeEntity.collegeImage1)
          that.introductionList.push(res.data.collegeEntity.collegeImage2)
          that.introductionList.push(res.data.collegeEntity.collegeImage3)
          // 高校排行
          if (res.data.collegeRankingEntity) {
            this.yearWithRanking =
              res.data.collegeRankingEntity.yearWithRanking
            this.eranking = [
              {
                title: '软科排行',
                num: res.data.collegeRankingEntity.bcuRanking
              },
              {
                title: '校友会排行',
                num: res.data.collegeRankingEntity.aaRankings
              },

              {
                title: 'usnews排行',
                num: res.data.collegeRankingEntity.ussRankings
              },
              {
                title: 'QS排行',
                num: res.data.collegeRankingEntity.qsRankings
              }
            ]
          } else {
            this.eranking = [
              {
                title: '软科排行',
                num: ''
              },
              {
                title: '校友会排行',
                num: ''
              },

              {
                title: 'usnews排行',
                num: ''
              },
              {
                title: 'QS排行',
                num: ''
              }
            ]
          }

          that.loading = false
          this.mainloading = false
        }
      })
    },
  // 优势专业
  getYszy(type){
      this.mqOrYs = type;
      if(this.mqOrYs == 1){   //优势专业
           getYszyDetail({collegeNumber:this.collegeNumber}).then(res=>{
              this.yszydialogVisible = true;
              this.advantageMajorName = res.data.advantageMajorName
          })
      }else if(this.mqOrYs == 2){    //名企招聘
             this.yszydialogVisible = true;
      }

  },
    changepi(type) {
      this.piciname = type;
      if (type == '提前批') {
        this.piciList = [
          { name: '本科提前批A段', value: '本科提前批A段' },
          { name: '本科提前批B段', value: '本科提前批B段' },
          { name: '本科提前批C段', value: '本科提前批C段' },
          { name: '专科提前批', value: '专科提前批' },
        ]
      } else {
        this.piciList = [
          { name: '本科批', value: '本科批' },
          { name: '专科批', value: '专科批' }
        ]
      }
      this.zsjhParams.batchFile =  this.piciList[0].value

      this.getDataGrade()
    },
    // 招生计划
    getDataGrade() {
      const self = this
      self.loading = true
      this.zsjhParams.collegeNumber = this.collegeNumber
      // if(!this.zsjhParams.batchFile){
      //     this.zsjhParams.batchFile =  this.piciList[0].value
      // }
      let obj = { ...this.zsjhParams }
      if (obj.recruitStudentsCategory == '不限') {
        obj.recruitStudentsCategory = ''
      }

      console.log(obj, '招生计划')
      REQqueryPlanList(obj).then((res) => {
        res.data.forEach(item => {
          for (const key in item) {
            if (!item[key]) {
              item[key] = '--'
            }
          }
        })

        this.zsjhList = res.data
        this.loading = false
      })
    },
    // 招生章程
    getEnrollmentguide() {
      const self = this
      self.loading = true
      const params = {
        collegeNumber: this.collegeNumber
      }
      REQenrollmentguide(params).then((res) => {
        if (res.code == 200) {
          self.arrList = res.data.enrollmentGuideEntities
          self.loading = false
        } else {
          self.loading = false
        }
      })
    },
    all() {
      this.dialogVisible = true
    },
    // 省发生变化，城市置为空       以暂定默认为河北省
    provideChange(name) {
      this.formInline.city = ''
      this.cityList.forEach((item) => {
        if (item.name == name) {
          this.wardenry = item.child
        }
      })
    },
    // 点击搜索按钮。按条件查询招生计划
    onSubmit() {
      this.getDataGrade()
    },

    // 录取分数列表
    luqufenshu() {
      this.loading = true
      this.lqfsParams.collegeNumber = this.collegeNumber
      REQqueryBatchScore(this.lqfsParams).then(res => {

        this.loading = false
        res.data.forEach(item => {
          for (const key in item.scorePlansEntity) {
            if (!item.scorePlansEntity[key]) {
              item.scorePlansEntity[key] = '--'
            }
          }
        })

        this.lqfsList = res.data.filter(item=>item.scorePlansEntity.batchFile == '本科批' || item.scorePlansEntity.batchFile == '专科批')
      })
    },
    // tab切换点击
    handClick(item) {
      this.activeId = item.id
      if (item.id == 2) {
        // 招生计划
        this.getDataGrade()
      } else if (item.id == 3) {
        // 录取分数
        this.luqufenshu()
      } else if (item.id == 4) {
        // 招生章程
        this.getEnrollmentguide()
      } else if (item.id == 0) {
        // 院校详情
        this.onload()
      }
      this.zsjzDetailFlag = false
      this.majorDetailFlag = false
    }
  }
}
</script>

<style scoped lang="scss">
$mainColor: #12b098;

.profile {
  box-sizing: border-box;
  width: 100%;
  // background-color: #fcfbf9;
  padding: 10px 0 80px 0;

  .screen {
    box-sizing: border-box;
    // padding: 40px 60px;
    width: 100%;
    border: 1px solid #f4f4f4;
    // border-radius: 10px;
    background: #12b098;
    display: flex;
    height: 302px;

    .left {
      display: flex;
      align-items: center;
      color: #666;
      margin: 80px 0 90px 38px;

      .centertitle {
        margin-left: 22px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }

      .btnbox {
        margin: 13px 0;

        span {
          font-size: 14px;
          box-sizing: border-box;
          display: inline-block;
          color: #09594d;

        }
      }

    }

    .screenImg {
      height: 100%;
      flex: 1;
      margin-left: 30px;
    }
  }

  .schools {
    display: flex;
    align-items: center;
    margin: 18px 0;

    .school_item {
      font-size: 16px;
      width: 25%;
      box-sizing: border-box;
      // padding-left: 62px;
      text-align: center;
      cursor: pointer;
      height: 40px;
      line-height: 40px;
      background: #f2f2f2;
      border-right: 1px solid #dfd6d6;

      img {
        vertical-align: sub;
        margin-right: 8px;
        width: 16px;
        height: 19px;
      }

      .jtimg {
        vertical-align: middle;
        width: 40px;
        height: 40px;
      }
    }

    .activeSchoolItem {
      background: rgb(18, 176, 152);
      color: white;
      border: none;
    }

    .other {
      width: 13%;
      padding-left: 0px;
      text-align: left;
      font-size: 16px;
      box-sizing: border-box;
      cursor: pointer;
      height: 40px;
      line-height: 40px;

      img {
        width: 40px;
        height: 40px;
        vertical-align: middle;
        margin-right: 8px;
      }
    }
  }

  .detailWrap {
    .schoolintro {
      font-size: 18px;
      font-weight: bold;
      color: #333333;
      padding-left: 15px;
      font-size: 16px;
      border-left: 3px solid #12b098;
    }

    .piciFather {
      height: 40px;
      line-height: 40px;
      display: flex;
      justify-content: space-between;
      padding: 0px;
      align-items: center;
    }

    .piciCss {
      height: 33px;
      font-size: 15px;
      line-height: 33px;
      background: #ccc;
      color: white;
      display: inline-block;
      width: 48%;
      margin-right: 1%;
      text-align: center;
      cursor: pointer;
      border-radius: 8px;
    }

    .active {
      background: rgb(18, 176, 152);
    }
  }

  .collegeslist {
    box-sizing: border-box;
    min-height: 740px;
    width: 100%;
    margin-top: 20px;
    border-radius: 10px;

    .title {
      font-weight: 900;
      font-size: 18px;
      margin: 10px 0px;
    }

    .line {
      height: 3px;
      width: 45px;
      background: $mainColor;
    }

    // 优势专业样式
    .AdvantageMajor {
      border: 1px solid #f3eded;
      border-radius: 10px;
      height: 120px;
      display: flex;
      margin: 15px 0px;

      .majorTitle {
        flex: 2;
        line-height: 120px;
        text-align: center;
        font-size: 18px;
        border-right: 1px solid #f3eded;
      }

      .majorName {
        flex: 8;
        font-size: 17px;
        cursor: pointer;

        span {
          padding: 10px;
          width: auto;
          display: inline-block;
        }

        span:hover {
          color: $mainColor;
        }
      }

      .common {
        line-height: 60px;
        text-align: center;
        height: 60px;
        background: rgb(244, 244, 244);
      }
    }

    // 优势专业详情样式
    .detailmajor {
      .detailTitle {
        font-size: 25px;
        font-weight: 900;
        text-align: center;
      }

      .histAndtea {
        margin: 15px 0px;
        font-weight: 800;
        font-size: 13px;
      }

      .top,
      .bottom {
        box-sizing: border-box;
        padding: 35px 30px;
        width: 100%;
        border: 1px solid #f4f4f4;
        border-radius: 10px;
        background: white;
      }

      .top {}

      .bottom {
        margin-top: 30px;

        .line {
          margin: 3px;
          height: 3px !important;
          width: 35px;
          background: #12b098;
          margin-bottom: 10px;
        }

        .title {
          font-weight: 900;
          font-size: 18px;
        }

        span {
          margin: 20px 0px;
          display: block;
        }


      }
    }
  }

  // 学校概括样式
  .introduction {
    display: flex;

    .left {
      margin: 0 15px;
    }



    .pdetail {
      // height: 250px;
      // overflow: hidden;
      text-overflow: ellipsis;
      text-indent: 30px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      padding: 10px 00px 0px 0px;
      line-height: 30px;
      margin-bottom: 15px;
      text-align: justify;
    }

    .ranking {
      width: 88px;
      height: 75px;
      vertical-align: top;
      font-size: 14px;
      line-height: 35px;
      text-align: center;
      display: flex;
      flex-direction: column;
      border: 1px solid #edebeb;
      display: inline-block;
      margin: 15px 14px;
      border-radius: 5px;

      span:nth-of-type(1) {
        color: $mainColor;
        font-size: 17px;
      }

      span {
        display: block;
      }
    }

    .right {
      width: 220px;

      span {
        color: black;
        line-height: 30px;
        margin: 10px auto;
      }

      .icon {
        display: inline-block;
        height: 26px;
        width: 20px;
        background: #12b098;
        color: white;
        line-height: 26px;
        margin-right: 30px;
      }
    }
  }

  // 招生章程样式
  .item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 60px;
    font-size: 16px;
    font-family: emoji;
    cursor: pointer;
    padding: 0 10px 0 15px;
    border-bottom: 1px solid #ccc;

    // border-bottom: 1px solid #f3eded;
    span:nth-of-type(1):hover {
      color: $mainColor;
    }
  }

  .search {
    position: absolute;
    top: 0;
    right: 0;
    height: 60px;
  }
}

::v-deep .el-dialog__title {
  font-weight: bold;
  font-size: 24px;
}

.textspan {
  color: red;
}

.text {
  width: 100px;
  height: 100px;
  border: 2px solid orange;
  border-radius: 50%;
  margin: auto;
  position: relative;

  .textno {
    width: 60px;
    height: 60px;
    font-size: 60px;
    color: orange;
    position: absolute;
    top: 12px;
    left: 37px;
    font-weight: 600;
  }




}

.textVip {
  font-size: 16px;
  text-align: center;
  // padding-left: 10\px;
  margin-top: 50px;


}

.btn {
  width: 30%;
  height: 40px;
  line-height: 40px;
  text-align: center;
  margin: 20px auto;
  font-size: 16px;
  color: #fff;
  background-color: red;
  border-radius: 30px;
  // margin-top: 30px;
  cursor: pointer;


}
.el-table .f13{
  font-size: 13px;color:#86909c
}
</style>
